<script setup lang="ts">
import { useCompanyStore } from '@/stores/companies'

const companiesStore = useCompanyStore()
const userStore = useUserStore()
</script>

<template>
  <div class="max-w-full min-h-screen flex flex-col bg-base-200">
    <slot name="admin-header">
      <ClientOnly>
        <ClientViewBar
          v-if="
            companiesStore.clientViewCompany &&
            companiesStore.clientViewCompany?.Id &&
            userStore.getSystemRole != 'client_user'
          "
        ></ClientViewBar>
      </ClientOnly>
    </slot>
    <slot name="header">
      <ClientOnly>
        <Header></Header>
      </ClientOnly>
    </slot>
    <slot name="progress" />
    <ClientOnly>
      <main class="container">
        <slot />
      </main>
    </ClientOnly>
    <slot name="footer">
      <Footer></Footer>
    </slot>
  </div>
</template>
